@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./up4ProductsCarousel__typography";

.up-4-products-carousel {
    position: relative;

    @include media-breakpoint-down(md) {
        padding-bottom: 16px;
    }

    &.margins-enabled {
        margin-top: 38px;

        @include media-breakpoint-up(md) {
            margin-top: 64px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 42px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 62px;
        }
    }
}

.up-4-products-text-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
    }
}

.up-4-products-carousel-title {
    margin-bottom: 8px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
    }
}

.up-4-products-carousel-description {
    margin-bottom: 0;
    text-align: center;
}

.up-4-products-carousel-carousel-wrapper {
    padding: 0 5px;
    position: relative;

    .swiper-button-prev {
        /* stylelint-disable-next-line */
        top: 30%;

        @include media-breakpoint-up(md) {
            top: 35%;
        }
    }

    .swiper-button-next {
        /* stylelint-disable-next-line */
        top: 30%;

        @include media-breakpoint-up(md) {
            top: 35%;
        }
    }

    .hide-arrow {
        display: flex;
    }

    .hide-bullets {
        display: none;
    }

    .swiper-slide {
        padding-right: 13px;
        padding-left: 13px;
    }
}
