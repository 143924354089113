@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";


.up-4-products-carousel-title {
    color: $brand-black;
    font-family: var(--font-primary);
    font-size: var(--text-xl);
    font-weight: 700;
    line-height: 32px;

    @include media-breakpoint-up(lg) {
        font-size: var(--text-2xl);
        line-height: 40px;
    }
}

.up-4-products-carousel-description {
    color: $brand-black;;
    font-family: var(--font-primary);
    font-size: var(--text-sm);
    line-height: 20px;

    @include media-breakpoint-up(lg) {
        font-size: var(--text-base);
        line-height: 24px;
    }
}
